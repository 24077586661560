const dummyCards = [
  { numberAsText: '14,962,960', description: 'Total minutes watched' },
  { numberAsText: '79,846', description: 'Registered users' },
  { numberAsText: '8,752', description: 'Supporters' }
];

const dummyLectures = [
  {
    text: 'The Power Of Rachels Tears - watched by 57,366 people',
    link: '/playlist/power-of-rachels-tears?video=rachel-weeping-for-her-children'
  },
  {
    text: "Tisha B'Av And The Messiah That Almost Was - watched by 25,573 people",
    link: '/playlist/king-chizkiah-the-almost-messiah?video=tisha-bav-hezekiah-judaism-messianic-age-expectation'
  },
  {
    text: 'The Hidden Structure Of The Ten Commandments - watched by 22,555 people',
    link: '/playlist/hidden-structure-of-ten-commandments?video=ten-commendments-judaism-meaning'
  },
  {
    text: 'Why Is Sukkot Celebrated? The Story Behind The Jewish Huts - watched by 18,412 people',
    link: '/playlist/sukkot-meaning-why-we-celebrate?video=why-is-sukkot-celebrated'
  },
  {
    text: "Sinat Chinam: The Great Tisha B'Av Crime - watched by 18,318 people",
    link: '/playlist/baseless-hatred-sinat-chinam?video=sinat-chinam-temple-destruction-bar-kamtza-tisha-bav'
  },
  {
    text: 'Eicha And Ayekah: Was There A Tisha B’Av In Eden? - watched by 18,181 people',
    link: '/playlist/megillat-eicha-and-ayekah-in-eden?video=megillat-eicha-and-ayeka-meaning'
  },
  {
    text: 'Does Oil Really Matter, After Winning A War? - watched by 16,330 people',
    link: '/playlist/what-does-hanukkah-celebrate?video=what-chanukah-commemorates'
  },
  {
    text: 'How Is 17 Tammuz Relevant Today? - watched by 14,431 people',
    link: '/playlist/how-is-17-tammuz-relevant-today?video=fast-of-tammuz-seventeen'
  },
  {
    text: 'Ruth: A Story 30 Generations In The Making - watched by 12,759 people',
    link: '/playlist/book-of-ruth?video=what-is-megillat-book-ruth-about-explained'
  },
  {
    text: 'The ‘Scandalous’ Backstory Of Boaz And Ruth - watched by 12,457 people',
    link: '/playlist/ruth-boaz-scandalous-backstory?video=boaz-ruth-bible-love-story-meaning'
  }
];

const dummyCourses = [
  {
    text: 'Rachel, Leah And The Mystery Of The Bartered Mandrakes - watched by 57,366 people',
    link: '/playlist/power-of-rachels-tears'
  },
  { text: 'Genesis Unveiled - watched by 40,172 people', link: '/playlist/the-garden-of-eden' },
  {
    text: 'The Triumph And Tragedy Of King Chizkiah - watched by 27,698 people',
    link: '/playlist/king-chizkiah-the-almost-messiah'
  },
  {
    text: 'The Joseph Story - watched by 27,059 peoplee',
    link: '/playlist/coats-dreams-and-jealousy'
  },
  {
    text: 'How To Prepare For Rosh Hashanah - watched by 25,776 people',
    link: '/playlist/prepare-for-rosh-hashanah'
  },
  {
    text: 'Why The Ten Commandments Matter In Our Daily Lives - watched by 22,555 people',
    link: '/playlist/hidden-structure-of-ten-commandments'
  },
  {
    text: 'The Meaning of Sukkot: Why On Earth Would We Celebrate Sleeping In Huts? - watched by 18,787 people',
    link: '/playlist/sukkot-meaning-why-we-celebrate'
  },
  {
    text: 'Understanding The Snake’s Most Insidious Lie - watched by 18,749 people',
    link: '/playlist/megillat-eicha-and-ayekah-in-eden'
  },
  {
    text: 'What Is Baseless Hatred, Anyway? - watched by 18,318 people',
    link: '/playlist/baseless-hatred-sinat-chinam'
  },
  {
    text: 'What Does Hanukkah Really Celebrate? - watched by 16,330 people',
    link: '/playlist/what-does-hanukkah-celebrate'
  }
];

const dummyStats = [
  {
    numberAsText: '83,628',
    description: 'People have watched 10 minutes or more in the past 3 months'
  },
  {
    numberAsText: '26,446',
    description: 'People have watched 10 minutes or more in the past month'
  },
  {
    numberAsText: '4,769',
    description: 'People have watched 30 minutes or more in the past month'
  },
  {
    numberAsText: '16,188',
    description: 'People have watched 20 - 50 videos'
  },
  {
    numberAsText: '971',
    description: 'People have watched 50 - 100 videos'
  },
  {
    numberAsText: '887',
    description: 'People have watched more than 100 videos'
  }
];

export { dummyCards, dummyCourses, dummyLectures, dummyStats };
