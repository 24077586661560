import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';

import Typography from '@material-ui/core/Typography';
import Link from '~components/Link';

const useStyles = makeStyles(theme => ({
  list: {
    paddingLeft: 0,
    listStyle: 'none'
  },
  listText: {
    fontWeight: 400
  },
  listItemPadding: {
    paddingBottom: theme.spacing(1)
  }
}));

const ImpactListItem = ({ index, text, link }) => {
  const classes = useStyles();
  return (
    <li className={classes.listItemPadding}>
      <Typography
        variant="h6"
        component={Link}
        to={link}
        color="textSecondary"
        className={classes.listText}
        gutterBottom
      >
        {index + 1}. {text}
      </Typography>
    </li>
  );
};
ImpactListItem.propTypes = {
  index: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired
};

const ImpactList = ({ items }) => {
  const classes = useStyles();
  return (
    <>
      <ol className={classes.list}>
        {items &&
          items.map((item, index) => <ImpactListItem key={item.text} index={index} {...item} />)}
      </ol>
    </>
  );
};

ImpactList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired
    })
  ).isRequired
};

export default ImpactList;
