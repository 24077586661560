import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  title: {
    position: 'relative',
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '60px',
      height: '3px',
      bottom: 0,
      left: 0,
      borderBottom: '3px solid #3687ff'
    }
  }
}));

const ImpactSection = ({ title, children }) => {
  const classes = useStyles();
  return (
    <>
      <Typography variant="h4" className={classes.title} gutterBottom>
        {title}
      </Typography>
      {children}
    </>
  );
};

ImpactSection.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

export default ImpactSection;
