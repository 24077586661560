import React from 'react';
import PropTypes from 'prop-types';

import { localeDate } from '~utils/dates';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import ImpactHeroLogo from '~images/impact/impact-hero-logo.png';
import LoadingSpinner from '~src/components/LoadingSpinner';

const useStyles = makeStyles(() => ({
  media: {
    display: 'flex',
    maxWidth: '100%'
  }
}));

const ImpactHero = ({ isLoading, isError }) => {
  const classes = useStyles();
  let message = `These numbers reflect our community‘s activity as of ${localeDate(new Date(), {
    month: 'long',
    day: 'numeric',
    year: 'numeric'
  })}`;

  if (isLoading) {
    message = 'Please wait until we load the latest numbers...';
  }
  if (isError) {
    message = 'We are sorry. Some error happened and we could not load the data.';
  }
  return (
    <Box pt={10}>
      <img src={ImpactHeroLogo} alt="Aleph Beta Logo" className={classes.media} />
      <Typography variant="subtitle1">
        Aleph Beta was founded in 2013. {message}
        {isLoading && <LoadingSpinner />}
      </Typography>
    </Box>
  );
};
ImpactHero.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired
};
export default ImpactHero;
