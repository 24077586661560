import React from 'react';

import Page from '~layout/Page';
import SEO from '~src/layout/SearchEngineOptimization';
import ImpactPageContents from '~src/containers/impact/ImpactPageContents';

const seo = {
  seo_title: 'Impact | Aleph Beta',
  seo_description:
    'See the reach and impact of Aleph Beta, a non-profit organization dedicated to Torah education and building an online Torah library.',
  seo_keywords: 'aleph beta audience'
};

const Impact = () => (
  <Page>
    <SEO {...seo} />
    <ImpactPageContents />
  </Page>
);

export default Impact;
