import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import request from '~utils/request';
import {
  IMPACT_PAGE_CARDS_DATA,
  IMPACT_PAGE_STATS_DATA,
  IMPACT_PAGE_TOP_DATA
} from '~src/hooks/useApi';
import Container from '~components/Container';
import ImpactList from '~components/impact/ImpactList';
import ImpactNumber from '~components/impact/ImpactNumber';
import ImpactSection from '~components/impact/ImpactSection';
import ImpactGrid from '~components/impact/ImpactGrid';
import ImpactHero from '~components/impact/ImpactHero';
import ImpactHeroBackground from '~images/impact/impact-hero-bg.jpg';
import LoadingSpinner from '~src/components/LoadingSpinner';
import linkResolver from '~src/prismic/utils/link-resolver';

import { dummyCards, dummyStats, dummyLectures, dummyCourses } from './ImpactPageContents.dummy';

const makeCards = data => {
  if (!data) {
    return dummyCards;
  }
  const intl = new Intl.NumberFormat('en-US');
  return [
    { numberAsText: intl.format(data.total_minutes), description: 'Total minutes watched' },
    { numberAsText: intl.format(data.registered_users), description: 'Registered users' },
    { numberAsText: intl.format(data.supporters), description: 'Supporters' }
  ];
};

const makeStats = data => {
  if (!data || !data.users) {
    return dummyStats;
  }
  const intl = new Intl.NumberFormat('en-US');
  return [
    {
      numberAsText: intl.format(data.users.more_10_min_in_3_months),
      description: 'People have watched 10 minutes or more in the past 3 months'
    },
    {
      numberAsText: intl.format(data.users.more_10_min_in_1_month),
      description: 'People have watched 10 minutes or more in the past month'
    },
    {
      numberAsText: intl.format(data.users.more_30_min_in_1_month),
      description: 'People have watched 30 minutes or more in the past month'
    },
    {
      numberAsText: intl.format(data.users.between_20_50_videos),
      description: 'People have watched 20 - 50 videos'
    },
    {
      numberAsText: intl.format(data.users.between_50_100_videos),
      description: 'People have watched 50 - 100 videos'
    },
    {
      numberAsText: intl.format(data.users.more_100_videos),
      description: 'People have watched more than 100 videos'
    }
  ];
};

const makePlaylists = data => {
  if (!data) {
    return dummyCourses;
  }
  return data.top_playlists.map(row => ({
    text: `${row.title} - watched by ${row.users} people`,
    link: linkResolver({ type: 'playlist', uid: row.uid })
  }));
};

const makeVideos = data => {
  if (!data) {
    return dummyLectures;
  }
  return data.top_videos.map(row => ({
    text: `${row.title} - watched by ${row.users} people`,
    link: linkResolver({ type: 'video', uid: row.uid, playlist_uid: row.playlist_uid })
  }));
};

const ImpactPageContents = () => {
  const [isCardsLoading, setIsCardsLoading] = useState(true);
  const [isStatsLoading, setIsStatsLoading] = useState(true);
  const [isTopLoading, setIsTopLoading] = useState(true);
  const [cards, setCards] = useState(dummyCards);
  const [stats, setStats] = useState(dummyStats);
  const [videos, setVideos] = useState(dummyLectures);
  const [playlists, setPlaylists] = useState(dummyCourses);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState(undefined);

  useEffect(() => {
    request(IMPACT_PAGE_CARDS_DATA())
      .then(axiosResponse => {
        setCards(makeCards(axiosResponse.data));
        setIsCardsLoading(false);
      })
      .catch(err => {
        setError(err);
        setIsError(true);
        setIsCardsLoading(false);
      });

    request(IMPACT_PAGE_STATS_DATA())
      .then(axiosResponse => {
        setStats(makeStats(axiosResponse.data));
        setIsStatsLoading(false);
      })
      .catch(err => {
        setError(err);
        setIsError(true);
        setIsStatsLoading(false);
      });

    request(IMPACT_PAGE_TOP_DATA())
      .then(axiosResponse => {
        setVideos(makeVideos(axiosResponse.data));
        setPlaylists(makePlaylists(axiosResponse.data));
        setIsTopLoading(false);
      })
      .catch(err => {
        setError(err);
        setIsError(true);
        setIsTopLoading(false);
      });
  }, []);

  useEffect(() => {
    if (error) {
      console.error(error);
    }
  }, [error]);

  return (
    <>
      <Container size={66} background={`url(${ImpactHeroBackground})`}>
        <ImpactHero
          isLoading={isStatsLoading || isCardsLoading || isTopLoading}
          isError={isError}
        />
      </Container>

      <Container background="#f7f9fb">
        <ImpactGrid items={cards} isLoading={isCardsLoading} />
      </Container>

      <Container size={66} center="vertical" background="#f7f9fb">
        <ImpactSection title="User Statistics">
          <Grid container direction="row" justify="space-between" alignItems="center" spacing={4}>
            {stats &&
              stats.map(stat => (
                <Grid item key={stat.description} xs={12} sm={6} md={5}>
                  <ImpactNumber {...stat} isLoading={isStatsLoading} />
                </Grid>
              ))}
          </Grid>
        </ImpactSection>
      </Container>

      <Container size={50} center="vertical">
        <ImpactSection title="Top Ten Videos">
          {isTopLoading ? <LoadingSpinner /> : <ImpactList items={videos} />}
        </ImpactSection>
      </Container>

      <Container size={50} center="vertical">
        <ImpactSection title="Top Ten Playlists">
          {isTopLoading ? <LoadingSpinner /> : <ImpactList items={playlists} />}
        </ImpactSection>
      </Container>
    </>
  );
};

export default ImpactPageContents;
