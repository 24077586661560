import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import AccessTimeOutlined from '@material-ui/icons/AccessTimeOutlined';
import PeopleOutlined from '@material-ui/icons/PeopleOutline';
import FavoriteOutlined from '@material-ui/icons/FavoriteBorderOutlined';

const useStyles = makeStyles(theme => ({
  blurText: {
    filter: 'blur(4px)'
  },
  paper: {
    padding: theme.spacing(3, 2)
  },
  icon: {
    fontSize: '4rem'
  },
  gridPosition: {
    marginTop: '-80px'
  }
}));

const Icon = ({ index, ...rest }) => {
  switch (index) {
    case 0:
      return <AccessTimeOutlined color="primary" {...rest} />;
    case 1:
      return <PeopleOutlined color="primary" {...rest} />;
    case 2:
      return <FavoriteOutlined color="secondary" {...rest} />;
    default:
      return <PeopleOutlined color="primary" {...rest} />;
  }
};

Icon.propTypes = {
  index: PropTypes.number.isRequired
};

const ImpactCard = ({ index, numberAsText, description, isLoading }) => {
  const classes = useStyles();
  return (
    <Paper elevation={4} className={classes.paper}>
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item xs md={3}>
          <Icon index={index} className={classes.icon} />
        </Grid>
        <Grid item xs md={9}>
          <Typography variant="h3" color="primary" className={isLoading ? classes.blurText : ''}>
            {numberAsText}
          </Typography>
          <Typography variant="subtitle1">{description}</Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

ImpactCard.propTypes = {
  numberAsText: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired
};

const ImpactGrid = ({ items, isLoading }) => {
  const classes = useStyles();
  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      className={classes.gridPosition}
      spacing={4}
    >
      {items &&
        items.map((card, index) => (
          <Grid item key={card.description} xs={12} sm={6} lg={4}>
            <ImpactCard index={index} isLoading={isLoading} {...card} />
          </Grid>
        ))}
    </Grid>
  );
};

ImpactGrid.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      numberAsText: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired
    })
  ).isRequired,
  isLoading: PropTypes.bool.isRequired
};

export default ImpactGrid;
