import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  description: {
    fontWeight: '400'
  },

  listItemPadding: {
    paddingTop: theme.spacing(1)
  },

  blurText: {
    filter: 'blur(4px)'
  }
}));

const ImpactNumber = ({ numberAsText, description, isLoading }) => {
  const classes = useStyles();
  return (
    <>
      <Typography
        variant="h2"
        color="primary"
        className={`${classes.listItemPadding} ${isLoading ? classes.blurText : ''} `}
      >
        {numberAsText}
      </Typography>
      <Typography variant="h6" color="textSecondary" className={classes.description}>
        {description}
      </Typography>
    </>
  );
};

ImpactNumber.propTypes = {
  numberAsText: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired
};

export default ImpactNumber;
